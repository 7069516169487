import React, { useState, useEffect } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';

const AmountSplitUp = (props) => {

    const [applicationFeeChecked, checkApplicationFee] = useState(false);
    const [uhfMonthlyFeeChecked, checkUhfMonthlyFee] = useState(true);
    const [monthlyShareChecked, checkMonthlyShare] = useState(true);
    const [amountBreakup, setAmountBreakup] = useState({
        applicationFee: 0.00,
        monthlyShare: 0.00,
        uhfMonthlyFee: 0.00,
        addOns: []
    });

    useEffect(() => {
        let _amountBreakup = props.amountBreakup;
        if (_amountBreakup) {
            _amountBreakup.applicationFee = parseFloat(_amountBreakup.applicationFee).toFixed(2);
            _amountBreakup.monthlyShare = parseFloat(_amountBreakup.monthlyShare).toFixed(2);
            _amountBreakup.uhfMonthlyFee = parseFloat(_amountBreakup.uhfMonthlyFee).toFixed(2);
            if(_amountBreakup.addOns && _amountBreakup.addOns.length>0){
                _amountBreakup.addOns.forEach((addOn)=>{
                    addOn.amount = parseFloat(addOn.amount).toFixed(2)
                })
            }
        }
        setAmountBreakup(_amountBreakup)
        // updateAmtBreakupDetails();
    }, [props.amountBreakup]);

    useEffect(() => {
        updateAmtBreakupDetails();
        console.log('updateAmtBreakupDetails')
    }, [applicationFeeChecked, monthlyShareChecked, uhfMonthlyFeeChecked, props.selectedPaymentMode, props.paymentType, amountBreakup])

    const cardMerchangePercent = 3.5;
    const getTotalAmount = () => {
        let total = 0;
        if (amountBreakup) {
            let { applicationFee, uhfMonthlyFee, monthlyShare, addOns } = amountBreakup;
            if (applicationFeeChecked) {
                total = total + parseFloat(applicationFee)
            }
            if (uhfMonthlyFeeChecked) {
                total = total + parseFloat(uhfMonthlyFee)
            }
            if (monthlyShareChecked) {
                total = total + parseFloat(monthlyShare)
            }

            if (addOns && addOns.length > 0) {
                addOns.forEach((addOn) => {
                    if (addOn.checked) {
                        total = total + parseFloat(addOn.amount);
                    }
                })
            }
            // total = parseFloat(applicationFee) + parseFloat(uhfMonthlyFee) + parseFloat(monthlyShare);
        }
        return total;
    }
    const merchantFeeCondition = () => {

        if (props.selectedPaymentMode == 'AccountOnFile' && amountBreakup.type == "Credit Card") {
            return true;
        } else {
            return props.selectedPaymentMode == 'Card' || (props.selectedPaymentMode == 'Transaction' && props.paymentType == 'Credit Card') || (props.selectedPaymentMode == 'Refund' && props.paymentType == 'Credit Card');
        }

    }

    const getMerchantFee = () => {


        let total = getTotalAmount();
        let merchantFee = 0;
        if (merchantFeeCondition()) {
            merchantFee = (total * cardMerchangePercent) / 100;
        }
        return merchantFee.toFixed(2);

        // if (props.selectedPaymentMode == 'AccountOnFile' && amountBreakup.merchantFee) {
        //     return amountBreakup.merchantFee;
        // }
        // else {
        //     let total = getTotalAmount();
        //     let merchantFee = 0;
        //     if (merchantFeeCondition()) {
        //         merchantFee = (total * cardMerchangePercent) / 100;
        //     }
        //     return merchantFee.toFixed(2);
        // }

    }

    const getTotalAmountAll = () => {
        let total = getTotalAmount();
        if (merchantFeeCondition()) {
            total = total + parseFloat(getMerchantFee());
        }
        return total.toFixed(2)
    }



    const updateAmtBreakupDetails = () => {
        let _updatedAmountBreakup = {
            cardmerchantFee: getMerchantFee(),
            total: getTotalAmount(),
            totalAmountAll: getTotalAmountAll(),
            applicationFee: applicationFeeChecked ? amountBreakup.applicationFee : 0,
            uhfMonthlyFee: uhfMonthlyFeeChecked ? amountBreakup.uhfMonthlyFee : 0,
            monthlyShare: monthlyShareChecked ? amountBreakup.monthlyShare : 0,
            addOns: amountBreakup.addOns
        }
        console.log(":::",_updatedAmountBreakup)
        props.updateAmountBreakUp(_updatedAmountBreakup);
    }

    const checkSelectedAddOn = (value, indexOfAddOn) => {
        let _amountBreakup = { ...amountBreakup }
        _amountBreakup.addOns[indexOfAddOn]['checked'] = value;
        setAmountBreakup(_amountBreakup)
    }

    if (amountBreakup && props.showAmountBreakup) {
        return <div className="amount-splitup" >

            {/* <TableContainer component={Paper}> */}
            <h6>Amount Split Up</h6>
            <Table style={{
                width: '350px'
            }} size="small" aria-label="a dense table">

                <TableBody>
                    <TableRow className="fees-type" >

                        <TableCell scope="row">
                            <Checkbox
                                checked={applicationFeeChecked}
                                onChange={(e) => { checkApplicationFee(e.target.checked) }}
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                className="amt-check-box"
                            />
                            <span className="fees-title">Application Fee</span>
                        </TableCell>
                        <TableCell align="left">{`$ ${amountBreakup.applicationFee}`}</TableCell>

                    </TableRow>
                    <TableRow className="fees-type">
                        <TableCell scope="row">
                            <Checkbox
                                checked={uhfMonthlyFeeChecked}
                                onChange={(e) => checkUhfMonthlyFee(e.target.checked)}
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                className="amt-check-box"
                            />
                            <span className="fees-title">UHF Monthly Fee</span>
                        </TableCell>
                        <TableCell align="left">{`$ ${amountBreakup.uhfMonthlyFee}`}</TableCell>
                    </TableRow>
                    <TableRow className="fees-type">
                        <TableCell scope="row">
                            <Checkbox
                                checked={monthlyShareChecked}
                                onChange={(e) => checkMonthlyShare(e.target.checked)}
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                className="amt-check-box"
                            />
                            <span className="fees-title">Monthly Share</span>

                        </TableCell>
                        <TableCell align="left">{`$ ${amountBreakup.monthlyShare}`}</TableCell>

                    </TableRow>

                    {
                        amountBreakup && amountBreakup.addOns &&
                        amountBreakup.addOns.map((addOn, index) => {
                            return (
                                <TableRow key={index} className="fees-type">
                                    <TableCell scope="row">
                                        <Checkbox
                                            checked={addOn.checked}
                                            onChange={(e) => checkSelectedAddOn(e.target.checked, index)}
                                            color="primary"
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            className="amt-check-box"
                                        />
                                        <span className="fees-title">{addOn.name}</span>

                                    </TableCell>
                                    <TableCell align="left">{`$ ${addOn.amount}`}</TableCell>

                                </TableRow>
                            )
                        })

                    }


                    {
                        merchantFeeCondition() &&
                        <TableRow className="total">
                            <TableCell scope="row" style={{
                                color: '#08af08'
                            }}>
                                <span className="fees-title">Merchant Fee ({cardMerchangePercent}%)</span>

                            </TableCell >
                            <TableCell align="left" style={{
                                color: '#08af08'
                            }}>$ {getMerchantFee()}</TableCell>

                        </TableRow>

                    }

                    <TableRow className="total">
                        <TableCell scope="row" style={{
                            fontWeight: 'bold'
                        }}>
                            <span className="fees-title">Total</span>
                        </TableCell>
                        <TableCell align="left" style={{
                            fontWeight: 'bold'
                        }}>$ {getTotalAmountAll()}</TableCell>

                    </TableRow>
                </TableBody>
            </Table>
            {/* </TableContainer> */}
        </div>
    } else {
        return null
    }

}

export default AmountSplitUp;