
import Card from '@material-ui/core/Card';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';

import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import './style.css'
import React from 'react'


import { Auth } from "aws-amplify";

const ForgotPasswordForm = (props) => {

  const handleSendVerification = (e) => {
    e.preventDefault();
    // props.setSendVerification(true)
    props.toggleLoader(true)
    Auth.forgotPassword(props.email)
      .then(data => {
        console.log('send verification')
        console.log(data)
        props.toggleLoader(false)
        props.handleSendVerification(data.CodeDeliveryDetails.Destination)

      })
      .catch(err => {
        props.toggleLoader(false);
        console.log(err)
      });
  }




  return (
    <div className="login">
      <CssBaseline />
      <Container maxWidth="xs">
        <Card className="login-card" style={{ marginTop: "50px" }}>

          <div className="logo">
            <img alt="logo" className="logo-custom" src={require('./images/auth-logo.jpg')} />
          </div>
          <form className="main-form" onSubmit={handleSendVerification} >
            <h4>Forgot your password?</h4>
            <p>Enter your Email below and we will send a message to reset your password.</p>
            <div className="a-form-ctrl">
              <p className="">Email</p>
              <input
                className="a-input"
                type="email"
                placeholder="Email"
                name="username"
                required
                onChange={props.handleChange}
              />
            </div>
            <div>
              <button type="submit" className="a-btn" >
                Reset my password
                </button>
            </div>
          </form>

        </Card>

      </Container>
    </div>
  )
}

export default ForgotPasswordForm
