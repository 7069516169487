import React, { useState, useEffect } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
// import Checkbox from '@material-ui/core/Checkbox';
import Radio from "@material-ui/core/Radio";

const RefundAmountSplitup = (props) => {
  const [applicationFeeChecked, checkApplicationFee] = useState(false);
  const [healthToolFeeChecked, checkHeathToolFee] = useState(false);
  const [rxSimpleFeeChecked, checkRxSimpleFee] = useState(false);
  const [uhfMonthlyFeeChecked, checkUhfMonthlyFee] = useState(false);
  const [totalAmtChcked, checkTotalAmnt] = useState(true);
  const [amountBreakup, setAmountBreakup] = useState({
    applicationFee: 0.0,
    monthlyShare: 0.0,
    uhfMonthlyFee: 0.0,
    healthToolAmount: 0.0,
    rxsimpleShareAmount: 0.0,
    totalAmountRefund: 0.0,
  });

  useEffect(() => {
    let _amountBreakup = props.amountBreakup;
    console.log("amt---", props.amountBreakup);
    if (_amountBreakup) {
      _amountBreakup.applicationFee = parseFloat(
        _amountBreakup.applicationFee
      ).toFixed(2);
      _amountBreakup.monthlyShare = parseFloat(
        _amountBreakup.monthlyShare
      ).toFixed(2);
      _amountBreakup.uhfMonthlyFee = parseFloat(
        _amountBreakup.uhfMonthlyFee
      ).toFixed(2);
      _amountBreakup.healthToolAmount = parseFloat(
        _amountBreakup.healthToolAmount
      ).toFixed(2);
      _amountBreakup.rxsimpleShareAmount = parseFloat(
        _amountBreakup.rxsimpleShareAmount
      ).toFixed(2);
      _amountBreakup.totalAmountRefund = parseFloat(
        _amountBreakup.totalAmountRefund
      ).toFixed(2);
    }
    setAmountBreakup(_amountBreakup);
    // updateAmtBreakupDetails();
  }, [props.amountBreakup]);

  useEffect(() => {
    updateAmtBreakupDetails();
    console.log("updateAmtBreakupDetails");
  }, [
    applicationFeeChecked,
    totalAmtChcked,
    healthToolFeeChecked,
    rxSimpleFeeChecked,
    props.selectedPaymentMode,
    props.paymentType,
    amountBreakup,
  ]);

  const updateAmtBreakupDetails = () => {
    let feeType = applicationFeeChecked && !rxSimpleFeeChecked && !healthToolFeeChecked
      ? "ApplicationFee"
      : healthToolFeeChecked && !applicationFeeChecked && !rxSimpleFeeChecked
      ? "HealthToolFee"
      : rxSimpleFeeChecked && !healthToolFeeChecked && !applicationFeeChecked
      ? "RxSimplaShareFee"
      :applicationFeeChecked && healthToolFeeChecked ? 
      "ApplicationHealthtool"
      : applicationFeeChecked && rxSimpleFeeChecked 
      ?"ApplicationRxSimpleShare"
      :applicationFeeChecked && healthToolFeeChecked && rxSimpleFeeChecked ?
      "ApplicationHealthRxsimple"
      :healthToolFeeChecked && rxSimpleFeeChecked 
      ?
      "HealthToolRxSimpleshare"
      :
      "TotalAmount";

    props.updateAmountBreakUp(feeType);
  };

  if (amountBreakup && props.showAmountBreakup) {
    if (props.paymentNumber == 1) {
      return (
        <div className="amount-splitup">
          <Table
            style={{
              width: "350px",
            }}
            size="small"
            aria-label="a dense table"
          >
            <TableBody>
              <TableRow className="fees-type">
                <TableCell scope="row">
                  <Radio
                    checked={applicationFeeChecked}
                    onChange={(e) => {
                      checkUhfMonthlyFee(false);
                      // checkRxSimpleFee(false);
                      // checkHeathToolFee(false);
                      checkTotalAmnt(false);
                      checkApplicationFee(e.target.checked);
                    }}
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    className="amt-check-box"
                  />
                  <span className="fees-title">Application Fee</span>
                </TableCell>
                <TableCell align="left">{`$ ${amountBreakup.applicationFee}`}</TableCell>
              </TableRow>
              {amountBreakup.healthToolAmount !== "NaN" && (
                <TableRow className="fees-type">
                  <TableCell scope="row">
                    <Radio
                      checked={healthToolFeeChecked}
                      onChange={(e) => {
                        checkUhfMonthlyFee(false);
                        checkTotalAmnt(false);
                        // checkRxSimpleFee(false);
                        checkHeathToolFee(e.target.checked);
                      }}
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      className="amt-check-box"
                    />
                    <span className="fees-title">HealthTool Fee</span>
                  </TableCell>
                  <TableCell align="left">{`$ ${amountBreakup.healthToolAmount}`}</TableCell>
                </TableRow>
              )}
              {amountBreakup.rxsimpleShareAmount !== "NaN" && (
                <TableRow className="fees-type">
                  <TableCell scope="row">
                    <Radio
                      checked={rxSimpleFeeChecked}
                      onChange={(e) => {
                        checkUhfMonthlyFee(false);
                        checkTotalAmnt(false);
                        // checkHeathToolFee(false)
                        checkRxSimpleFee(e.target.checked);
                      }}
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      className="amt-check-box"
                    />
                    <span className="fees-title">RxSimpleShare Fee</span>
                  </TableCell>
                  <TableCell align="left">{`$ ${amountBreakup.rxsimpleShareAmount}`}</TableCell>
                </TableRow>
              )}
              <TableRow className="fees-type">
                <TableCell scope="row">
                  <Radio
                    checked={totalAmtChcked}
                    onChange={(e) => {
                      checkTotalAmnt(e.target.checked);
                      checkUhfMonthlyFee(false);
                      checkHeathToolFee(false);
                      checkRxSimpleFee(false);
                      checkApplicationFee(false);
                    }}
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    className="amt-check-box"
                  />
                  <span className="fees-title">Total Amount</span>
                </TableCell>
                <TableCell align="left">{`$ ${amountBreakup.totalAmountRefund}`}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      );
    } else {
      return (
        <div
          className="amount-splitup"
          style={{
            padding: "10px 0",
          }}
        >
          <Table
            style={{
              width: "350px",
            }}
            size="small"
            aria-label="a dense table"
          >
            <TableBody>
              {amountBreakup.healthToolAmount !== "NaN" && (
                <TableRow className="fees-type">
                  <TableCell scope="row">
                    <Radio
                      checked={healthToolFeeChecked}
                      onChange={(e) => {
                        checkUhfMonthlyFee(false);
                        checkTotalAmnt(false);
                        // checkRxSimpleFee(false);
                        checkHeathToolFee(e.target.checked);
                      }}
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      className="amt-check-box"
                    />
                    <span className="fees-title">HealthTool Fee</span>
                  </TableCell>
                  <TableCell align="left">{`$ ${amountBreakup.healthToolAmount}`}</TableCell>
                </TableRow>
              )}
              {amountBreakup.rxsimpleShareAmount !== "NaN" && (
                <TableRow className="fees-type">
                  <TableCell scope="row">
                    <Radio
                      checked={rxSimpleFeeChecked}
                      onChange={(e) => {
                        checkUhfMonthlyFee(false);
                        checkTotalAmnt(false);
                        // checkHeathToolFee(false)
                        checkRxSimpleFee(e.target.checked);
                      }}
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      className="amt-check-box"
                    />
                    <span className="fees-title">RxSimpleShare Fee</span>
                  </TableCell>
                  <TableCell align="left">{`$ ${amountBreakup.rxsimpleShareAmount}`}</TableCell>
                </TableRow>
              )}

              {/* <TableRow className="fees-type">
                <TableCell scope="row">
                  <span className="fees-title">Total Amount</span>
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    fontWeight: "bold",
                  }}
                >{`$ ${amountBreakup.totalAmountRefund}`}</TableCell>
              </TableRow> */}

              <TableRow className="fees-type">
                <TableCell scope="row">
                  <Radio
                    checked={totalAmtChcked}
                    onChange={(e) => {
                      checkTotalAmnt(e.target.checked);
                      checkUhfMonthlyFee(false);
                      checkHeathToolFee(false);
                      checkRxSimpleFee(false);
                      checkApplicationFee(false);
                    }}
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    className="amt-check-box"
                  />
                  <span className="fees-title">Total Amount</span>
                </TableCell>
                <TableCell align="left">{`$ ${amountBreakup.totalAmountRefund}`}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      );
    }
  } else {
    return null;
  }
};

export default RefundAmountSplitup;
